<template>
  <ion-page>
    <ion-content fullscreen>
      <qrcode-stream :camera="camera" :track="selected.value" @decode="onDecode" />

      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="handleClosedQrCode">
          <ion-icon :icon="closeOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonPage, 
  IonContent,
  useIonRouter,
  IonFab,
  IonFabButton,
  IonIcon,
} from '@ionic/vue'
import { closeOutline } from 'ionicons/icons'
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { QrcodeStream } from 'qrcode-reader-vue3'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'qr-scanner',
  components: {
    IonPage,
    IonContent,
    QrcodeStream,
    IonFab,
    IonFabButton,
    IonIcon
  },
  setup() {
    let qrCodeError = ref('auto')
    let camera = ref('auto')
    
    const router = useIonRouter()
    const route = useRoute()
    const store = useStore()
    
    const onDecode = (data) => {
      // console.log('json data: ', data)
      store.dispatch('Qrcode/saveQrcode', JSON.parse(data))
      camera.value = 'off'
      router.push({
        name: 'inspection-info',
        params: {
          num: route.params.num
        }
      })
    }

    const paintOutline = (detectedCodes, ctx) => {
      for (const detectedCode of detectedCodes) {
        const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

        ctx.strokeStyle = 'red'

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }

      return ctx
    }

    const selected = paintOutline

    const logErrors = (promise) => {
      return promise.catch(console.error)
    }

    const handleClosedQrCode = () => {
      camera.value = 'off'
      // router.back()
      // router.push('/checkup/check')
      location.href = "/checkup/check"
    }

    return {
      closeOutline,
      camera,
      qrCodeError,
      onDecode,
      selected,
      paintOutline,
      logErrors,
      handleClosedQrCode
      // onInit
    }
  },
})
</script>

<style scoped>
:deep ion-avatar {
  --border-radius: 2px
}

:deep ion-item .item-native {
  --padding-start: 0
}
</style>
